import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../services/AuthProvider"
import LoginStatus from '../../enums/login-status.enum'

const AuthGuard = ({ component: UIComponent }: any) => {

    const navigate = useNavigate()

    const { state } = useAuth()

    useEffect(() => {
        if (state.status != LoginStatus.LOGGED_IN) {
            navigate('/')
            document.title = 'Private System'
        } else {
            document.title = 'Personal Bills'
        }
    }, [state.status])

    return state.status == LoginStatus.LOGGED_IN ? <UIComponent /> : <></>

}

export default AuthGuard
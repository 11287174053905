import { Modal, Button, Form } from 'react-bootstrap'
import { AlertModalData } from '../../interfaces/alert-modal-data'

const AlertDialog = ({ show, title, message, onClose }: AlertModalData) => {

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter-global-alert" centered backdrop="static" show={show}>
            <Modal.Header className='bg-dark'>
                <Modal.Title id="contained-modal-title-vcenter-global-alert">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-dark'>
                <Form.Label color='light'>{message}</Form.Label>
            </Modal.Body>
            <Modal.Footer className='bg-dark'>
                <Button size='sm' onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AlertDialog
import { AxiosResponse } from "axios"
import { useQuery } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const getWellBeingBills = () => {
    return AxiosAPI.get('/well-being')
}

export const GET_INTERNET_BILLS_QUERY = 'GET_INTERNET_BILLS_QUERY'

const useGetWellBeingBillsQuery = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { isLoading, isFetching, data, error, isError } = useQuery([GET_INTERNET_BILLS_QUERY], getWellBeingBills, {
        refetchOnWindowFocus: false,
        onSuccess
    })

    return { getWellBeingBillsData: data, isGetWellBeingBillsError: isError, isGetWellBeingBillsLoading: isLoading, isGetWellBeingBillsFetching: isFetching, getWellBeingBillsError: error }
}

export default useGetWellBeingBillsQuery
import axios from 'axios'

const AxiosAPI = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

AxiosAPI.interceptors.request.use((req: any) => {
    if (localStorage.getItem('token'))
        req.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return req;
})

export default AxiosAPI
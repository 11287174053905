import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"
import { BillUpdateStatusRequest } from "../interfaces/bill-update-status-request"

const updateWellBeingBillStatus = ({ billId, status }: BillUpdateStatusRequest) => {
    return AxiosAPI.put(`/well-being/${billId}`, { status })
}

const useUpdateWellBeingBillStatusQuery = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(updateWellBeingBillStatus, {
        onSuccess
    })

    return { updateWellBeingBillStatusMutate: mutate, updateWellBeingBillStatusData: data, isUpdateWellBeingBillStatusError: isError, isUpdateWellBeingBillStatusLoading: isLoading, updateWellBeingBillStatusError: error }
}

export default useUpdateWellBeingBillStatusQuery
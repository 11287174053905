import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import AxiosAPI from "../config/AxiosConfig"

const authRequest = () => {
    return AxiosAPI.get('/auth')
}

const useAuthMutation = () => {

    const navigate = useNavigate()

    const { mutate, data, isError, isLoading, error } = useMutation(authRequest, {
        onSuccess: (data => {
            navigate('/bills')
        })
    })

    return { authMutate: mutate, authData: data, isAuthError: isError, isAuthLoading: isLoading, authError: error }
}

export default useAuthMutation
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { Badge, Button, Spinner, Table, Form, Col, Row } from 'react-bootstrap'
import { useQueryClient } from 'react-query'
import { AlertModalData } from '../../../interfaces/alert-modal-data'
import { ConfirmModalData } from '../../../interfaces/confirm-modal-data'
import { useAuth } from '../../../services/AuthProvider'
import useDeleteAllWellBeingBillsMutation from '../../../services/useDeleteAllWellBeingBillsMutation'
import useDeleteWellBeingBillMutation from '../../../services/useDeleteWellBeingBillMutation'
import useGetWellBeingBillsQuery, { GET_INTERNET_BILLS_QUERY } from '../../../services/useGetWellBeingBillsQuery'
import useUpdateWellBeingBillStatusQuery from '../../../services/useUpdateWellBeingBillStatusQuery'
import AlertDialog from '../../utility/AlertDialog'
import ConfirmDialog from '../../utility/ConfirmDialog'
import AddNewWellBeingBill from './AddNewWellBeingBill'

const resetConfirmModalData = {
    show: false,
    title: '',
    message: '',
    onConfirmYes: () => { },
    onConfirmNo: () => { }
}

const resetAlertModalData = {
    show: false,
    title: '',
    message: '',
    onClose: () => { }
}

const WellBeingBills = () => {

    const queryClient = useQueryClient()
    const { getToken, triggerUpdate } = useAuth()
    const [showNewBillModal, setShowNewBillModal] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [confirmModalData, setConfirmModalData] = useState<ConfirmModalData>(resetConfirmModalData)
    const [alertModalData, setAlertModalData] = useState<AlertModalData>(resetAlertModalData)
    const [wellBeingBills, setWellBeingBills] = useState<any>([])
    const [bId, setBId] = useState<string>('')
    const [selectionAmountSum, setSelectionAmountSum] = useState(0)
    const [doneAmountSum, setDoneAmountSum] = useState(0)

    const getDownloadUrl = (billId: string): string => `${process.env.REACT_APP_BASE_URL}/well-being/download/${billId}?token=${getToken()}`

    const { isGetWellBeingBillsLoading, isGetWellBeingBillsFetching, getWellBeingBillsError, isGetWellBeingBillsError } = useGetWellBeingBillsQuery((data: AxiosResponse<any, any>) => {
        setWellBeingBills(data?.data.map((bd: any) => {
            bd.isSelected = false
            return bd
        }))
    })

    const { updateWellBeingBillStatusMutate, isUpdateWellBeingBillStatusError, isUpdateWellBeingBillStatusLoading, updateWellBeingBillStatusError } = useUpdateWellBeingBillStatusQuery(() => {
        queryClient.invalidateQueries([GET_INTERNET_BILLS_QUERY])
        triggerUpdate()
        setBId('')
    })

    const { deleteWellBeingBillMutate, isDeleteWellBeingBillError, isDeleteWellBeingBillLoading, deleteWellBeingBillError } = useDeleteWellBeingBillMutation(() => {
        queryClient.invalidateQueries([GET_INTERNET_BILLS_QUERY])
        triggerUpdate()
        setBId('')
    })

    const { deleteAllWellBeingBillsMutate, isDeleteAllWellBeingBillsError, isDeleteAllWellBeingBillsLoading, deleteAllWellBeingBillsError } = useDeleteAllWellBeingBillsMutation((data: AxiosResponse<any, any>) => {
        queryClient.invalidateQueries([GET_INTERNET_BILLS_QUERY])
        triggerUpdate()
        setAlertModalData({
            show: true,
            title: 'All Well Being Bills Deleted',
            message: `${data?.data.deletedCount} Well Being Bills have been Deleted Successfully`,
            onClose: () => {
                setAlertModalData(resetAlertModalData)
            }
        })
    })

    useEffect(() => {
        let selSum = 0, doneSum = 0, selCount = 0
        wellBeingBills.forEach((b: any) => {
            if (b.status == 'DONE')
                doneSum += b.amount
            if (b.isSelected) {
                selSum += b.amount
                selCount++
            }
        })
        setSelectAll(selCount == wellBeingBills.length && wellBeingBills.length)
        setDoneAmountSum(doneSum)
        setSelectionAmountSum(selSum)
    }, [wellBeingBills])

    if (isGetWellBeingBillsLoading || isGetWellBeingBillsFetching || isDeleteAllWellBeingBillsLoading) {
        return <><div className='text-center'><br /><Spinner style={{ width: 50, height: 50 }} animation="border" variant="primary" /></div></>
    }

    if (isGetWellBeingBillsError) {
        return <><div className='text-center'><br />Something went wrong!<br />{getWellBeingBillsError instanceof Error && getWellBeingBillsError.message}</div></>
    }

    if (isDeleteAllWellBeingBillsError) {
        return <><div className='text-center'><br />Something went wrong!<br />{deleteAllWellBeingBillsError instanceof Error && deleteAllWellBeingBillsError.message}</div></>
    }

    const reloadBills = (reload: boolean) => {
        if (reload)
            queryClient.invalidateQueries([GET_INTERNET_BILLS_QUERY])
        setShowNewBillModal(false)
    }

    const updateBillStatus = (billId: string, status: string) => {
        setBId(billId)
        updateWellBeingBillStatusMutate({ billId, status })
    }

    const deleteWellBeingBillConfirmation = (billId: string) => {
        setConfirmModalData({
            show: true,
            title: 'Confirm Well Being Bill Deletion',
            message: 'Are you sure to Delete the selected Well Being Bill?',
            onConfirmYes: () => {
                setBId(billId)
                setConfirmModalData(resetConfirmModalData)
                deleteWellBeingBillMutate(billId)
            },
            onConfirmNo: () => {
                setConfirmModalData(resetConfirmModalData)
            }
        })
    }

    const deleteAllWellBeingBillsConfirmation = () => {
        setConfirmModalData({
            show: true,
            title: 'Confirm All Well Being Bills Deletion',
            message: 'Are you sure to Delete all the Well Being Bills?',
            onConfirmYes: () => {
                setConfirmModalData(resetConfirmModalData)
                deleteAllWellBeingBillsMutate()
            },
            onConfirmNo: () => {
                setConfirmModalData(resetConfirmModalData)
            }
        })
    }

    const setChecked = (value: boolean, billId: string = '') => {
        if (billId == '') {
            setWellBeingBills((fb: any) => fb.map((f: any) => { f.isSelected = value; return f }))
            setSelectAll(value)
        } else {
            setWellBeingBills((fb: any) => fb.map((f: any) => { if (f._id == billId) f.isSelected = value; return f }))
        }
    }

    return <>
        <br />
        <h1 className='text-primary display-6'>Well Being Bills</h1>
        <hr />
        <Row>
            <Col>
                <Form.Label>Selection Total: <strong>INR {Number(selectionAmountSum).toFixed(2)}</strong></Form.Label><br />
                <Form.Label>DONE Total: <strong>INR {Number(doneAmountSum).toFixed(2)}</strong></Form.Label>
            </Col>
            <Col sm={4} />
            <Col style={{ textAlign: 'right' }}>
                <Button variant="outline-warning" size='sm' onClick={() => setShowNewBillModal(true)}><i className='fas fa-plus-circle'></i> Add New WellBeing Bill</Button>
                {wellBeingBills.length != 0 && <>{'  '}
                    <Button variant="outline-danger" size='sm' onClick={deleteAllWellBeingBillsConfirmation}><i className='fas fa-trash-alt'></i> Delete All WellBeing Bills</Button></>}
            </Col>
        </Row>
        <br />
        <Table striped bordered hover responsive variant="dark">
            <thead>
                <tr>
                    <th>#</th>
                    <th> <Form.Check style={{ cursor: 'pointer' }} type="checkbox" id="select-all" checked={selectAll} onChange={$e => setChecked($e.target.checked)} /> </th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Bill / Invoice</th>
                    <th>Status</th>
                    <th>Submission Pay Period</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    wellBeingBills.map((billData: any, index: number) => <tr key={billData._id}>
                        <td> {index + 1} </td>
                        <td>
                            <Form.Group className="mb-3" controlId="exampleForm.c" style={{ cursor: 'pointer' }}>
                                <Form.Check type="checkbox" checked={billData.isSelected} onChange={$e => setChecked($e.target.checked, billData._id)} />
                            </Form.Group>
                        </td>
                        <td> {`${billData.date}`.substring(0, 10)} </td>
                        <td> &#8377; {Number(billData.amount).toFixed(2)} </td>
                        <td> <a className="btn btn-sm btn-outline-secondary" href={getDownloadUrl(billData.invoiceFileName)}><i className='fas fa-download'></i> Download</a> </td>
                        <td>
                            <Badge bg={billData.status == 'DUE' ? 'danger' : 'success'}> {billData.status} </Badge>
                        </td>
                        <td> {billData.payPeriod && `${billData.payPeriod}`.substring(0, 10)} </td>
                        <td>
                            {(isUpdateWellBeingBillStatusLoading || isDeleteWellBeingBillLoading) && bId == billData._id && <Spinner animation="border" variant="warning" />}
                            {isUpdateWellBeingBillStatusError && bId == billData._id && JSON.stringify(updateWellBeingBillStatusError)}
                            {isDeleteWellBeingBillError && bId == billData._id && JSON.stringify(deleteWellBeingBillError)}

                            {((!isUpdateWellBeingBillStatusLoading && !isUpdateWellBeingBillStatusError && !isDeleteWellBeingBillLoading && !isDeleteWellBeingBillError) || ((isUpdateWellBeingBillStatusLoading || isUpdateWellBeingBillStatusError || isDeleteWellBeingBillLoading || isDeleteWellBeingBillError) && bId != billData._id)) && (billData.status == 'DUE' ? <><Button variant='outline-success' size='sm' onClick={() => updateBillStatus(billData._id, 'DONE')}><i className='fas fa-check-circle'></i></Button>{'  '}</> : <><Button variant='outline-danger' size='sm' onClick={() => updateBillStatus(billData._id, 'DUE')}><i className='fas fa-times-circle'></i></Button>{'  '}</>)}

                            {((!isUpdateWellBeingBillStatusLoading && !isUpdateWellBeingBillStatusError && !isDeleteWellBeingBillLoading && !isDeleteWellBeingBillError) || ((isUpdateWellBeingBillStatusLoading || isUpdateWellBeingBillStatusError || isDeleteWellBeingBillLoading || isDeleteWellBeingBillError) && bId != billData._id)) && <Button variant='outline-danger' size='sm' onClick={() => deleteWellBeingBillConfirmation(billData._id)}><i className='fas fa-trash-alt'></i></Button>}
                        </td>
                    </tr>)
                }
            </tbody>
        </Table>
        <div style={{ position: 'fixed', right: 20, bottom: 10 }}>
            <Form.Label>Selection Total: <strong>INR {Number(selectionAmountSum).toFixed(2)}</strong></Form.Label>
        </div>
        <AddNewWellBeingBill show={showNewBillModal} onHide={(reload: boolean) => reloadBills(reload)} />
        <ConfirmDialog {...confirmModalData} />
        <AlertDialog {...alertModalData} />
    </>
}

export default WellBeingBills
import { AxiosResponse } from "axios"
import { useQuery } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const getInternetBills = () => {
    return AxiosAPI.get('/internet')
}

export const GET_INTERNET_BILLS_QUERY = 'GET_INTERNET_BILLS_QUERY'

const useGetInternetBillsQuery = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { isLoading, isFetching, data, error, isError } = useQuery([GET_INTERNET_BILLS_QUERY], getInternetBills, {
        refetchOnWindowFocus: false,
        onSuccess
    })

    return { getInternetBillsData: data, isGetInternetBillsError: isError, isGetInternetBillsLoading: isLoading, isGetInternetBillsFetching: isFetching, getInternetBillsError: error }
}

export default useGetInternetBillsQuery
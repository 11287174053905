import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { useState } from 'react'
import useAddNewWellBeingBillMutation from '../../../services/useAddNewWellBeingBillMutation'
import { Bill } from '../../../interfaces/bill'

const currentDate = new Date()
const minEnabledDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2).toISOString().substring(0, 10)
const maxEnabledDate = currentDate.toISOString().substring(0, 10)

const AddNewWellBeingBill = ({ show, onHide }: any) => {

    const [files, setFiles] = useState<FileList>()

    const [wellBeingBillInfo, setWellBeingBillInfo] = useState<Bill>({
        date: '2022-08-12',
        amount: 125.00
    })

    const { addNewWellBeingBillMutate, isAddNewWellBeingBillError, isAddNewWellBeingBillLoading, addNewWellBeingBillError } = useAddNewWellBeingBillMutation(() => {
        onHide(true)
    })

    const addNewBill = () => {
        if (files != null && files.length > 0) {
            const file = files.item(0)
            if (file != null)
                addNewWellBeingBillMutate({
                    billInfo: wellBeingBillInfo,
                    bill: file
                })
        }
    }

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" show={show}>
            <Modal.Header className='bg-dark'>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload and Add New Bill
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-dark'>
                <>
                    <Form.Group className="mb-3" controlId="exampleForm.c1">
                        <Form.Label>Bill Date</Form.Label>
                        <Form.Control type="date" max={maxEnabledDate} onChange={$e => setWellBeingBillInfo({ ...wellBeingBillInfo, date: $e.target.value })} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.c3">
                        <Form.Label>Bill Amount</Form.Label>
                        <Form.Control type="number" min="1.00" onChange={$e => setWellBeingBillInfo({ ...wellBeingBillInfo, amount: Number($e.target.value) })} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.c4">
                        <label className='btn btn-secondary'>
                            Choose Bill (PDF)
                            <input type="file" accept='application/pdf' onChange={$e => setFiles($e.target.files || new FileList())} hidden />
                        </label>
                        {(files != null && files.length > 0) ? files.item(0)?.name : ''}
                    </Form.Group>
                    {isAddNewWellBeingBillLoading && <Spinner animation="border" variant="warning" />}
                    {isAddNewWellBeingBillError && JSON.stringify(addNewWellBeingBillError)}
                </>
            </Modal.Body>
            <Modal.Footer className='bg-dark'>
                <Button size='sm' onClick={addNewBill}>Save</Button>
                <Button size='sm' onClick={() => onHide(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddNewWellBeingBill
import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const deleteAllWellBeingBills = () => {
    return AxiosAPI.delete(`/well-being`)
}

const useDeleteAllWellBeingBillsMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(deleteAllWellBeingBills, {
        onSuccess
    })

    return { deleteAllWellBeingBillsMutate: mutate, deleteAllWellBeingBillsData: data, isDeleteAllWellBeingBillsError: isError, isDeleteAllWellBeingBillsLoading: isLoading, deleteAllWellBeingBillsError: error }
}

export default useDeleteAllWellBeingBillsMutation
import { useEffect } from "react"
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { useAuth } from "../../services/AuthProvider"
import useAuthMutation from "../../services/useAuthMutation"

const Home = () => {

    const { authError, isAuthError, isAuthLoading, authMutate } = useAuthMutation()
    const [searchParams] = useSearchParams()
    const { login } = useAuth()

    useEffect(() => {
        const token = searchParams.get('token')
        if (token) {
            login(token)
            authMutate()
        }
    }, [])

    if (isAuthLoading) {
        return <div className='text-center'><br /><Spinner style={{ width: 50, height: 50 }} animation="border" variant="primary" /></div>
    }

    if (isAuthError) {
        return <div className='text-center'><br />Something went wrong!<br />{authError instanceof Error && authError.message}</div>
    }

    const signIn = () => {
        window.location.href = `${process.env.REACT_APP_AUTH_URL}?appId=${process.env.REACT_APP_APP_ID}`
    }

    return <>
        <br />
        <Alert variant="danger" style={{ textAlign: 'center' }}>
            This is a private system. Only specific users who are allowed have been granted access. Please contact system administrator for any queries.
        </Alert>
        <Row>
            <Col>
                <Form.Label color="warning">Please Sign In to Continue</Form.Label>
            </Col>
            <Col sm={4} />
            <Col style={{ textAlign: 'right' }}>
                <Button variant="outline-success" size='sm' onClick={signIn}><i className='fas fa-sign-in-alt'></i> Sign In with SSO</Button>
            </Col>
        </Row>
        <br />
    </>
}

export default Home
import { AxiosResponse } from "axios"
import { useQuery } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const getCommuteBills = () => {
    return AxiosAPI.get('/commute')
}

export const GET_COMMUTE_BILLS_QUERY = 'GET_COMMUTE_BILLS_QUERY'

const useGetCommuteBillsQuery = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { isLoading, isFetching, data, error, isError } = useQuery([GET_COMMUTE_BILLS_QUERY], getCommuteBills, {
        refetchOnWindowFocus: false,
        onSuccess
    })

    return { getCommuteBillsData: data, isGetCommuteBillsError: isError, isGetCommuteBillsLoading: isLoading, isGetCommuteBillsFetching: isFetching, getCommuteBillsError: error }
}

export default useGetCommuteBillsQuery
import { useState, useEffect } from "react";
import { Badge, Button, CloseButton, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GlobalConstants from "../../config/GlobalConstants";
import { useAuth } from "../../services/AuthProvider";
import LoginStatus from '../../enums/login-status.enum'
import useBillDueCountQuery from "../../services/useBillDueCountQuery";

const MainHeader = () => {

    const navigate = useNavigate()
    const [navOpen, setNavOpen] = useState<boolean>(false)
    const { logout, state, setCounts } = useAuth()

    const { billDueCountData, billDueCountRefetch } = useBillDueCountQuery()

    useEffect(() => {
        if (state.status == LoginStatus.LOGGED_IN && (!state.dueCounts || state.trigger)) {
            billDueCountRefetch()
        }
    }, [state])

    useEffect(() => {
        if (billDueCountData) {
            setCounts(billDueCountData.data)
        }
    }, [billDueCountData])

    const closeDrawerAndNavigate = (path: string) => {
        setNavOpen(false)
        navigate(path)
    }

    return (
        <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
            {state.status != LoginStatus.LOGGED_IN && <Navbar.Brand>&nbsp;&nbsp;{GlobalConstants.DEFAULT_TITLE}</Navbar.Brand>}
            {state.status == LoginStatus.LOGGED_IN && <>
                <Navbar.Brand style={{ cursor: 'pointer' }} onClick={() => navigate('/bills')}>&nbsp;&nbsp;{GlobalConstants.APP_TITLE}</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setNavOpen(true)} />
                <Navbar.Offcanvas show={navOpen} id="offcanvasNavbar-expand" aria-labelledby="offcanvasNavbarLabel-expand" placement="end" className="bg-dark">
                    <Offcanvas.Header>
                        <Offcanvas.Title id="offcanvasNavbarLabel-expand"> {GlobalConstants.APP_TITLE} </Offcanvas.Title>
                        <CloseButton variant="white" onClick={() => setNavOpen(false)} />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="me-auto text-secondary margin-rtb-auto" style={{ fontStyle: 'italic' }}>{'Hi ' + state.email + '!'}</Nav>
                        <Nav>
                            <Nav.Link onClick={() => closeDrawerAndNavigate('/bills/commute')}>Commute Bills &nbsp;&nbsp;{state.dueCounts?.commute != 0 && <Badge bg="danger">{state.dueCounts?.commute}</Badge>}</Nav.Link>
                            <Nav.Link onClick={() => closeDrawerAndNavigate('/bills/internet')}>Internet Bills &nbsp;&nbsp;{state.dueCounts?.internet != 0 && <Badge bg="danger">{state.dueCounts?.internet}</Badge>}</Nav.Link>
                            <Nav.Link onClick={() => closeDrawerAndNavigate('/bills/well-being')}>Well Being Bills &nbsp;&nbsp;{state.dueCounts?.wellbeing != 0 && <Badge bg="danger">{state.dueCounts?.wellbeing}</Badge>}</Nav.Link>
                            <Button className="margin-rtb-auto" variant="outline-primary" size='sm' onClick={() => logout()}><i className='fas fa-sign-in-alt'></i> Sign Out</Button>&nbsp;&nbsp;
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </>}
        </Navbar>
    );
}

export default MainHeader;
import { useNavigate, Outlet } from 'react-router-dom';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';

const Bills = () => {

    const navigate = useNavigate()

    return <>
        <br />
        <Alert variant="warning" style={{ textAlign: 'center' }}>
            All Expired DUE <strong>Internet</strong> Bills would be Automatically Deleted on 1st Day of Next Month from the Bill Month at 12:00 Noon IST.
        </Alert>
        <Alert variant="success" style={{ textAlign: 'center' }}>
            All Older DONE Bills would be Automatically Deleted on Last Date of Next Pay Period (at 12:00 Noon IST) from the Pay Period in which the Bill was Marked as DONE.
        </Alert>
        <Row>
            <Col>
                <Card>
                    <Card.Body className='bg-dark'>
                        <Card.Title className='text-primary'>Commute Bills</Card.Title>
                        <Card.Text>
                            View all commute bills available. Upload from your phone and download when needed.
                        </Card.Text>
                        <Button variant='outline-warning' size='sm' style={{ display: 'block' }} onClick={() => navigate('commute')}>View Commute Bills{'    '}<i className='fas fa-chevron-right'></i></Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card>
                    <Card.Body className='bg-dark'>
                        <Card.Title className='text-primary'>Internet Bills</Card.Title>
                        <Card.Text>
                            View all internet bills available. Upload from your phone and download when needed.
                        </Card.Text>
                        <Button variant='outline-warning' size='sm' style={{ display: 'block' }} onClick={() => navigate('internet')}>View Internet Bills{'    '}<i className='fas fa-chevron-right'></i></Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card>
                    <Card.Body className='bg-dark'>
                        <Card.Title className='text-primary'>Well Being Bills</Card.Title>
                        <Card.Text>
                            View all well being bills available. Upload from your phone and download when needed.
                        </Card.Text>
                        <Button variant='outline-warning' size='sm' style={{ display: 'block' }} onClick={() => navigate('well-being')}>View Well Being Bills{'    '}<i className='fas fa-chevron-right'></i></Button>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Outlet />
    </>
}

export default Bills
import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"
import { BillRequest } from "../interfaces/bill-request"

const addBill = ({ billInfo, bill }: BillRequest) => {
    let formData: FormData = new FormData()
    if (bill != null)
        formData.append('bill', bill)
    formData.append('billInfo', JSON.stringify(billInfo))
    return AxiosAPI.post('/commute', formData)
}

const useAddNewCommuteBillMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(addBill, {
        onSuccess
    })

    return { addNewCommuteBillMutate: mutate, addNewCommuteBillData: data, isAddNewCommuteBillError: isError, isAddNewCommuteBillLoading: isLoading, addNewCommuteBillError: error }
}

export default useAddNewCommuteBillMutation
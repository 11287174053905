import { Modal, Button, Form } from 'react-bootstrap'
import { ConfirmModalData } from '../../interfaces/confirm-modal-data'

const ConfirmDialog = ({ show, title, message, onConfirmYes, onConfirmNo }: ConfirmModalData) => {

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter-global-confirm" centered backdrop="static" show={show}>
            <Modal.Header className='bg-dark'>
                <Modal.Title id="contained-modal-title-vcenter-global-confirm">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-dark'>
                <Form.Label color='light'>{message}</Form.Label>
            </Modal.Body>
            <Modal.Footer className='bg-dark'>
                <Button size='sm' onClick={onConfirmNo}>No</Button>
                <Button size='sm' onClick={onConfirmYes}>Yes</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmDialog
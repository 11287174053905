import { createContext, useContext, useReducer } from "react"
import AuthContextInfo from "../interfaces/auth-context-info"
import LoginStatus from '../enums/login-status.enum'
import BillDueCount from "../interfaces/bill-due-count"

const AuthContext = createContext<AuthContextInfo>({ state: { status: LoginStatus.CHECKING, email: '', dueCounts: undefined, trigger: false }, login: () => { }, logout: () => { }, getToken: () => '', getEmail: () => '', setCounts: () => { }, triggerUpdate: () => { } })

const AuthProvider = ({ children }: any) => {

    const getToken = (): string => localStorage.getItem('token') || ''

    const decodeUserFromToken = (): any => JSON.parse(window.atob(getToken().split('.')[1]))

    const isLoggedIn = (): boolean => {
        try {
            decodeUserFromToken()
            return true;
        } catch (e) {
            return false;
        }
    }

    const getEmail = (): string => isLoggedIn() ? decodeUserFromToken().email : ''

    const [state, dispatch] = useReducer
        (
            (previousState: any, action: { status: LoginStatus, email: string, dueCounts?: BillDueCount, trigger: boolean }) => {
                return action
            },
            isLoggedIn() ? { status: LoginStatus.LOGGED_IN, email: getEmail(), trigger: false } : { status: LoginStatus.CHECKING, email: '', trigger: false }
        )

    const logout = () => {
        localStorage.removeItem('token')
        dispatch({ status: LoginStatus.LOGGED_OUT, email: '', dueCounts: undefined, trigger: false })
    }

    const login = (token: string | null) => {
        localStorage.setItem('token', token || '')
        dispatch({ status: LoginStatus.LOGGED_IN, email: getEmail(), trigger: false })
    }

    const setCounts = (dueCounts: BillDueCount) => {
        dispatch({ status: state.status, email: state.email, dueCounts, trigger: false })
    }

    const triggerUpdate = () => {
        dispatch({ status: state.status, email: state.email, dueCounts: state.dueCounts, trigger: true })
    }

    return <AuthContext.Provider value={{ state, login, logout, getToken, getEmail, setCounts, triggerUpdate }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext);
}

export default AuthProvider;
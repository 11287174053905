import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Home from './components/home/Home'
import Bills from './components/bills/Bills'
import CommuteBills from './components/bills/commute-bills/CommuteBills'
import InternetBills from './components/bills/internet-bills/InternetBills'
import WellBeingBills from './components/bills/well-being-bills/WellBeingBills'
import { QueryClient, QueryClientProvider } from 'react-query'
import AuthGuard from './components/auth/AuthGuard'
import AuthProvider from './services/AuthProvider'
import MainHeader from './components/utility/MainHeader'

const queryClient = new QueryClient()

const App = () => {
  return <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <BrowserRouter>
        <MainHeader />
        <Container>
          <Routes>
            <Route index element={<Home />} />
            <Route path="bills" element={<AuthGuard component={Bills} />} />
            <Route path="bills/commute" element={<AuthGuard component={CommuteBills} />} />
            <Route path="bills/internet" element={<AuthGuard component={InternetBills} />} />
            <Route path="bills/well-being" element={<AuthGuard component={WellBeingBills} />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </AuthProvider>
  </QueryClientProvider>
}

export default App

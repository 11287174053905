import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"
import { BillUpdateStatusRequest } from "../interfaces/bill-update-status-request"

const updateInternetBillStatus = ({ billId, status }: BillUpdateStatusRequest) => {
    return AxiosAPI.put(`/internet/${billId}`, { status })
}

const useUpdateInternetBillStatusQuery = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(updateInternetBillStatus, {
        onSuccess
    })

    return { updateInternetBillStatusMutate: mutate, updateInternetBillStatusData: data, isUpdateInternetBillStatusError: isError, isUpdateInternetBillStatusLoading: isLoading, updateInternetBillStatusError: error }
}

export default useUpdateInternetBillStatusQuery
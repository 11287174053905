import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"
import { BillUpdateStatusRequest } from "../interfaces/bill-update-status-request"

const updateCommuteBillStatus = ({ billId, status }: BillUpdateStatusRequest) => {
    return AxiosAPI.put(`/commute/${billId}`, { status })
}

const useUpdateCommuteBillStatusQuery = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(updateCommuteBillStatus, {
        onSuccess
    })

    return { updateCommuteBillStatusMutate: mutate, updateCommuteBillStatusData: data, isUpdateCommuteBillStatusError: isError, isUpdateCommuteBillStatusLoading: isLoading, updateCommuteBillStatusError: error }
}

export default useUpdateCommuteBillStatusQuery
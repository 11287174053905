import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"
import { BillRequest } from "../interfaces/bill-request"

const addBill = ({ billInfo, bill }: BillRequest) => {
    let formData: FormData = new FormData()
    if (bill != null)
        formData.append('bill', bill)
    formData.append('billInfo', JSON.stringify(billInfo))
    return AxiosAPI.post('/well-being', formData)
}

const useAddNewWellBeingBillMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(addBill, {
        onSuccess
    })

    return { addNewWellBeingBillMutate: mutate, addNewWellBeingBillData: data, isAddNewWellBeingBillError: isError, isAddNewWellBeingBillLoading: isLoading, addNewWellBeingBillError: error }
}

export default useAddNewWellBeingBillMutation
import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const deleteAllInternetBills = () => {
    return AxiosAPI.delete(`/internet`)
}

const useDeleteAllInternetBillsMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(deleteAllInternetBills, {
        onSuccess
    })

    return { deleteAllInternetBillsMutate: mutate, deleteAllInternetBillsData: data, isDeleteAllInternetBillsError: isError, isDeleteAllInternetBillsLoading: isLoading, deleteAllInternetBillsError: error }
}

export default useDeleteAllInternetBillsMutation
import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const deleteAllCommuteBills = () => {
    return AxiosAPI.delete(`/commute`)
}

const useDeleteAllCommuteBillsMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(deleteAllCommuteBills, {
        onSuccess
    })

    return { deleteAllCommuteBillsMutate: mutate, deleteAllCommuteBillsData: data, isDeleteAllCommuteBillsError: isError, isDeleteAllCommuteBillsLoading: isLoading, deleteAllCommuteBillsError: error }
}

export default useDeleteAllCommuteBillsMutation
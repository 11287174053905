import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const deleteWellBeingBill = (billId: string) => {
    return AxiosAPI.delete(`/well-being/${billId}`)
}

const useDeleteWellBeingBillMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(deleteWellBeingBill, {
        onSuccess
    })

    return { deleteWellBeingBillMutate: mutate, deleteWellBeingBillData: data, isDeleteWellBeingBillError: isError, isDeleteWellBeingBillLoading: isLoading, deleteWellBeingBillError: error }
}

export default useDeleteWellBeingBillMutation
import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const deleteCommuteBill = (billId: string) => {
    return AxiosAPI.delete(`/commute/${billId}`)
}

const useDeleteCommuteBillMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(deleteCommuteBill, {
        onSuccess
    })

    return { deleteCommuteBillMutate: mutate, deleteCommuteBillData: data, isDeleteCommuteBillError: isError, isDeleteCommuteBillLoading: isLoading, deleteCommuteBillError: error }
}

export default useDeleteCommuteBillMutation
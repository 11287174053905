import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { Badge, Button, Spinner, Table, Form, Col, Row } from 'react-bootstrap'
import { useQueryClient } from 'react-query'
import { AlertModalData } from '../../../interfaces/alert-modal-data'
import { ConfirmModalData } from '../../../interfaces/confirm-modal-data'
import { useAuth } from '../../../services/AuthProvider'
import useDeleteAllCommuteBillsMutation from '../../../services/useDeleteAllCommuteBillsMutation'
import useDeleteCommuteBillMutation from '../../../services/useDeleteCommuteBillMutation'
import useGetCommuteBillsQuery, { GET_COMMUTE_BILLS_QUERY } from '../../../services/useGetCommuteBillsQuery'
import useUpdateCommuteBillStatusQuery from '../../../services/useUpdateCommuteBillStatusQuery'
import AlertDialog from '../../utility/AlertDialog'
import ConfirmDialog from '../../utility/ConfirmDialog'
import AddNewCommuteBill from './AddNewCommuteBill'

const resetConfirmModalData = {
    show: false,
    title: '',
    message: '',
    onConfirmYes: () => { },
    onConfirmNo: () => { }
}

const resetAlertModalData = {
    show: false,
    title: '',
    message: '',
    onClose: () => { }
}

const CommuteBills = () => {

    const queryClient = useQueryClient()
    const { getToken, triggerUpdate } = useAuth()
    const [showNewBillModal, setShowNewBillModal] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [confirmModalData, setConfirmModalData] = useState<ConfirmModalData>(resetConfirmModalData)
    const [alertModalData, setAlertModalData] = useState<AlertModalData>(resetAlertModalData)
    const [commuteBills, setCommuteBills] = useState<any>([])
    const [bId, setBId] = useState<string>('')
    const [selectionAmountSum, setSelectionAmountSum] = useState(0)
    const [doneAmountSum, setDoneAmountSum] = useState(0)

    const getDownloadUrl = (billId: string): string => `${process.env.REACT_APP_BASE_URL}/commute/download/${billId}?token=${getToken()}`

    const { isGetCommuteBillsLoading, isGetCommuteBillsFetching, getCommuteBillsError, isGetCommuteBillsError } = useGetCommuteBillsQuery((data: AxiosResponse<any, any>) => {
        setCommuteBills(data?.data.map((bd: any) => {
            bd.isSelected = false
            return bd
        }))
    })

    const { updateCommuteBillStatusMutate, isUpdateCommuteBillStatusError, isUpdateCommuteBillStatusLoading, updateCommuteBillStatusError } = useUpdateCommuteBillStatusQuery(() => {
        queryClient.invalidateQueries([GET_COMMUTE_BILLS_QUERY])
        triggerUpdate()
        setBId('')
    })

    const { deleteCommuteBillMutate, isDeleteCommuteBillError, isDeleteCommuteBillLoading, deleteCommuteBillError } = useDeleteCommuteBillMutation(() => {
        queryClient.invalidateQueries([GET_COMMUTE_BILLS_QUERY])
        triggerUpdate()
        setBId('')
    })

    const { deleteAllCommuteBillsMutate, isDeleteAllCommuteBillsError, isDeleteAllCommuteBillsLoading, deleteAllCommuteBillsError } = useDeleteAllCommuteBillsMutation((data: AxiosResponse<any, any>) => {
        queryClient.invalidateQueries([GET_COMMUTE_BILLS_QUERY])
        triggerUpdate()
        setAlertModalData({
            show: true,
            title: 'All Commute Bills Deleted',
            message: `${data?.data.deletedCount} Commute Bills have been Deleted Successfully`,
            onClose: () => {
                setAlertModalData(resetAlertModalData)
            }
        })
    })

    useEffect(() => {
        let selSum = 0, doneSum = 0, selCount = 0
        commuteBills.forEach((b: any) => {
            if (b.status == 'DONE')
                doneSum += b.amount
            if (b.isSelected) {
                selSum += b.amount
                selCount++
            }
        })
        setSelectAll(selCount == commuteBills.length && commuteBills.length)
        setDoneAmountSum(doneSum)
        setSelectionAmountSum(selSum)
    }, [commuteBills])

    if (isGetCommuteBillsLoading || isGetCommuteBillsFetching || isDeleteAllCommuteBillsLoading) {
        return <><div className='text-center'><br /><Spinner style={{ width: 50, height: 50 }} animation="border" variant="primary" /></div></>
    }

    if (isGetCommuteBillsError) {
        return <><div className='text-center'><br />Something went wrong!<br />{getCommuteBillsError instanceof Error && getCommuteBillsError.message}</div></>
    }

    if (isDeleteAllCommuteBillsError) {
        return <><div className='text-center'><br />Something went wrong!<br />{deleteAllCommuteBillsError instanceof Error && deleteAllCommuteBillsError.message}</div></>
    }

    const reloadBills = (reload: boolean) => {
        if (reload)
            queryClient.invalidateQueries([GET_COMMUTE_BILLS_QUERY])
        setShowNewBillModal(false)
    }

    const updateBillStatus = (billId: string, status: string) => {
        setBId(billId)
        updateCommuteBillStatusMutate({ billId, status })
    }

    const deleteCommuteBillConfirmation = (billId: string) => {
        setConfirmModalData({
            show: true,
            title: 'Confirm Commute Bill Deletion',
            message: 'Are you sure to Delete the selected Commute Bill?',
            onConfirmYes: () => {
                setBId(billId)
                setConfirmModalData(resetConfirmModalData)
                deleteCommuteBillMutate(billId)
            },
            onConfirmNo: () => {
                setConfirmModalData(resetConfirmModalData)
            }
        })
    }

    const deleteAllCommuteBillsConfirmation = () => {
        setConfirmModalData({
            show: true,
            title: 'Confirm All Commute Bills Deletion',
            message: 'Are you sure to Delete all the Commute Bills?',
            onConfirmYes: () => {
                setConfirmModalData(resetConfirmModalData)
                deleteAllCommuteBillsMutate()
            },
            onConfirmNo: () => {
                setConfirmModalData(resetConfirmModalData)
            }
        })
    }

    const setChecked = (value: boolean, billId: string = '') => {
        if (billId == '') {
            setCommuteBills((fb: any) => fb.map((f: any) => { f.isSelected = value; return f }))
            setSelectAll(value)
        } else {
            setCommuteBills((fb: any) => fb.map((f: any) => { if (f._id == billId) f.isSelected = value; return f }))
        }
    }

    return <>
        <br />
        <h1 className='text-primary display-6'>Commute Bills</h1>
        <hr />
        <Row>
            <Col>
                <Form.Label>Selection Total: <strong>INR {Number(selectionAmountSum).toFixed(2)}</strong></Form.Label><br />
                <Form.Label>DONE Total: <strong>INR {Number(doneAmountSum).toFixed(2)}</strong></Form.Label>
            </Col>
            <Col sm={4} />
            <Col style={{ textAlign: 'right' }}>
                <Button variant="outline-warning" size='sm' onClick={() => setShowNewBillModal(true)}><i className='fas fa-plus-circle'></i> Add New Commute Bill</Button>
                {commuteBills.length != 0 && <>{'  '}
                    <Button variant="outline-danger" size='sm' onClick={deleteAllCommuteBillsConfirmation}><i className='fas fa-trash-alt'></i> Delete All Commute Bills</Button></>}
            </Col>
        </Row>
        <br />
        <Table striped bordered hover responsive variant="dark">
            <thead>
                <tr>
                    <th>#</th>
                    <th> <Form.Check style={{ cursor: 'pointer' }} type="checkbox" id="select-all" checked={selectAll} onChange={$e => setChecked($e.target.checked)} /> </th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Bill / Invoice</th>
                    <th>Status</th>
                    <th>Submission Pay Period</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    commuteBills.map((billData: any, index: number) => <tr key={billData._id}>
                        <td> {index + 1} </td>
                        <td>
                            <Form.Group className="mb-3" controlId="exampleForm.c" style={{ cursor: 'pointer' }}>
                                <Form.Check type="checkbox" checked={billData.isSelected} onChange={$e => setChecked($e.target.checked, billData._id)} />
                            </Form.Group>
                        </td>
                        <td> {`${billData.date}`.substring(0, 10)} </td>
                        <td> &#8377; {Number(billData.amount).toFixed(2)} </td>
                        <td> <a className="btn btn-sm btn-outline-secondary" href={getDownloadUrl(billData.invoiceFileName)}><i className='fas fa-download'></i> Download</a> </td>
                        <td>
                            <Badge bg={billData.status == 'DUE' ? 'danger' : 'success'}> {billData.status} </Badge>
                        </td>
                        <td> {billData.payPeriod && `${billData.payPeriod}`.substring(0, 10)} </td>
                        <td>
                            {(isUpdateCommuteBillStatusLoading || isDeleteCommuteBillLoading) && bId == billData._id && <Spinner animation="border" variant="warning" />}
                            {isUpdateCommuteBillStatusError && bId == billData._id && JSON.stringify(updateCommuteBillStatusError)}
                            {isDeleteCommuteBillError && bId == billData._id && JSON.stringify(deleteCommuteBillError)}

                            {((!isUpdateCommuteBillStatusLoading && !isUpdateCommuteBillStatusError && !isDeleteCommuteBillLoading && !isDeleteCommuteBillError) || ((isUpdateCommuteBillStatusLoading || isUpdateCommuteBillStatusError || isDeleteCommuteBillLoading || isDeleteCommuteBillError) && bId != billData._id)) && (billData.status == 'DUE' ? <><Button variant='outline-success' size='sm' onClick={() => updateBillStatus(billData._id, 'DONE')}><i className='fas fa-check-circle'></i></Button>{'  '}</> : <><Button variant='outline-danger' size='sm' onClick={() => updateBillStatus(billData._id, 'DUE')}><i className='fas fa-times-circle'></i></Button>{'  '}</>)}

                            {((!isUpdateCommuteBillStatusLoading && !isUpdateCommuteBillStatusError && !isDeleteCommuteBillLoading && !isDeleteCommuteBillError) || ((isUpdateCommuteBillStatusLoading || isUpdateCommuteBillStatusError || isDeleteCommuteBillLoading || isDeleteCommuteBillError) && bId != billData._id)) && <Button variant='outline-danger' size='sm' onClick={() => deleteCommuteBillConfirmation(billData._id)}><i className='fas fa-trash-alt'></i></Button>}
                        </td>
                    </tr>)
                }
            </tbody>
        </Table>
        <div style={{ position: 'fixed', right: 20, bottom: 10 }}>
            <Form.Label>Selection Total: <strong>INR {Number(selectionAmountSum).toFixed(2)}</strong></Form.Label>
        </div>
        <AddNewCommuteBill show={showNewBillModal} onHide={(reload: boolean) => reloadBills(reload)} />
        <ConfirmDialog {...confirmModalData} />
        <AlertDialog {...alertModalData} />
    </>
}

export default CommuteBills
import { useQuery } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const billDueCountRequest = () => {
    return AxiosAPI.get('/auth/due-counts')
}

export const GET_BILL_DUE_COUNTS = 'GET_BILL_DUE_COUNTS'

const useBillDueCountQuery = () => {

    const { refetch, isFetching, data, isError, isLoading, error } = useQuery([GET_BILL_DUE_COUNTS], billDueCountRequest, {
        enabled: false
    })

    return { billDueCountRefetch: refetch, billDueCountData: data, isBillDueCountError: isError, isBillDueCountLoading: isLoading, billDueCountError: error }
}

export default useBillDueCountQuery
import { AxiosResponse } from "axios"
import { useMutation } from "react-query"
import AxiosAPI from "../config/AxiosConfig"

const deleteInternetBill = (billId: string) => {
    return AxiosAPI.delete(`/internet/${billId}`)
}

const useDeleteInternetBillMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(deleteInternetBill, {
        onSuccess
    })

    return { deleteInternetBillMutate: mutate, deleteInternetBillData: data, isDeleteInternetBillError: isError, isDeleteInternetBillLoading: isLoading, deleteInternetBillError: error }
}

export default useDeleteInternetBillMutation